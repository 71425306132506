@media (width >= 1024px) {
  .story-list .row-one {
    flex-basis: 40%;
    display: flex;
    align-items: center;
  }

  .row-two {
    flex-basis: 60%;
    flex-direction: row;
  }

  .row-two-column-one,
  .row-three-column-one {
    flex: 0.8;
  }

  .row-four-column-one {
    flex: 1;
  }

  .inner-column-half {
    flex: 1;
  }

  #story-score {
    flex: 0.6;
  }

  #story-time {
    flex: 0.8;
  }
}

@media (width >=745px) and (width <=1023px) {
  .story-list .row-one {
    flex-basis: 50%;
  }

  .row-two {
    flex-basis: 50%;
    flex-direction: column;
  }

  .inner-column-half {
    flex: 1;
  }
}

@media (width <= 744px) {
  .story-list .column-two {
    flex-wrap: wrap;
  }

  .row-one {
    flex-basis: 100%;
  }

  .row-two {
    flex-basis: 100%;
    flex-direction: column;
  }

  .inner-column-half {
    flex: 1;
  }
}

.story-list {
  border-bottom: 1px solid #f60;
  background-color: white;
  margin-bottom: 15px;
  padding: 12px;
  display: flex;
  gap: 12px;
}

.story-list .column-one {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rank {
  margin-bottom: 5px;
}

.row-two {
  display: flex;
  gap: 10px;
}

.column-two {
  width: 100%;
  display: flex;
  gap: 10px;
}

.story-list .row-one {
  min-height: 40px;
}

.row-one h4 {
  margin: 0;
  line-height: 20px;
}

.row-two-column-one,
.row-three-column-one,
.row-four-column-one {
  font-size: 14px;
  display: flex;
}

a {
  text-decoration: none;
  color: #15135f;
  word-break: break-word;
}

.inner-column-full,
.inner-column-half {
  display: flex;
  align-items: center;
  gap: 5px;
}

.inner-column-full a,
.inner-column-half a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row-one a:hover,
.inner-column-full a:hover,
.inner-column-half a:hover {
  color: #f60;
}
