.story-container {
  margin: 80px 0 auto;
  padding: 0 28px;
  color: #15135f;
  max-width: 1200px;
  width: 100%;
}

.heading {
  font-family: Coda, cursive;
  margin: 0;
  color: #15135f;
}

.story-list-group {
  padding: 0;
}

.btn-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.prev-btn,
.next-btn {
  width: 110px;
  height: 35px;
  color: #15135f;
  border: 1px solid #15135f;
  margin: 15px 0 30px;
}

.prev-btn:disabled {
  color: white;
  background-color: #888;
  border: none;
  cursor: default;
}

.prev-btn:hover:not(:disabled),
.next-btn:hover {
  cursor: pointer;
  border: none;
  color: white;
  background-color: #f60;
}
