@media (width >=1024px) {
  .skeleton.story-list {
    height: 40px;
  }
}

@media (width >=745px) and (width <=1023px) {
  .skeleton.story-list {
    height: 75px;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 125px;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(191, 191, 191, 0.1);
  }

  100% {
    background-color: rgba(191, 191, 191, 0.5);
  }
}

body {
  background-color: #eee;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
